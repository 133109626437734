@import "./app/shared/styles/variables.scss";
@import "../node_modules/ngx-guided-tour/scss/guided-tour-base-theme.scss";
@import "./app/shared/styles/mixins.scss";
@import "./app/shared/styles/theming.scss";
@import "@material-design-icons/font";

html, body {
  height: 100%;
  margin: 0;
}

a.clickable {
  cursor: pointer;
}

.mat-app-background,
.app-component {
  height: 100%;
}

.column {
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 20px;
}

img.avatar {
  display: inline-block;
  border-radius: 100%;
}

// .cell-wrapper {
//   display: flex;
//   align-items: center;
//   gap: 7px;
//   width: max-content;
//   padding: 3px 5px;
// }

.cell-wrapper {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 3px 5px;
  overflow: hidden;
  max-width: 100%;
   & > span { /* Target the <span> containing the text */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0; /* Allows the text element to shrink */
    flex-shrink: 1; /* Ensures the text element can shrink if necessary */
  }
}

.org-table-item-avatar {
  width: 32px;
  height: 32px;
}

.profile-avatar {
  width: 72px;
  height: 72px;
  position: relative;
  display: inline-block;
  .avatar {
    background-image: url("./assets/user/anonymous.png");
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position-x: center;
    background-repeat: no-repeat;
    border-radius: 100%;
    position: absolute;
  }
}

.empty-slate {
  padding: 40px;
  display: flex;
  height: 100%;
  min-height: 300px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.wrapper-1 {
  max-width: 555px;
  margin: 0 auto;
  position: relative;
  width: calc(100% - 40px);
}

.wrapper-2 {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  width: calc(100% - 40px);
}

.content-grid-1 {
  display: grid;
}

.masonry-grid {
  display: block;
  column-count: 2;
  column-gap: 16px;
  width: 100%;
  > .masonry-item {
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
    padding-bottom: 16px;
    width: 100%;
    overflow: hidden;
  }

  @media (max-width: 1080px) {
    column-count: 1;
  }
}

.main-logo {
  width: 92px;
  height: 32px;
  position: relative;
  display: block;
  top: 4px;
  &.alt-size1 {
    width: 151px;
    height: initial;
  }
  &.margin {
    margin: 20px 0;
  }
  &.absolute {
    position: absolute;
    top: 11px;
    left: 25px;
  }
  &.left {
    left: 30px;
  }
}

.component-header {
  background-color: transparent;
  margin-bottom: 20px;
  .second-row {
    display: grid;
    grid-template-columns: 1fr auto;
    .action {
      text-align: right;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
      align-self: center;
    }
  }
  .component-filter-top {
    display: block;
  }
}

form {
  .suffix {
    text-transform: uppercase;
    font-size: 12px;
  }
  .suffix-standard {
    @extend .suffix;
  }
  .suffix-action {
    @extend .suffix;
  }
}

.mat-horizontal-content-container {
  padding-left: 0;
  padding-right: 0;
}

.onboard {
  height: 100%;
  .stepper,
  .billing-form {
    margin: auto;
  }
}

.stepper-footer {
  padding: 17px;
  .action {
    &.left {
      float: left;
    }
  }
}

.table-center {
  display: table !important;
  margin: auto;
  text-align: center;
  margin-bottom: 25px;
  line-height: 28px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.columns-2 {
  grid-template-columns: 100%;
  display: grid;
  gap: 20px;
  align-items: start;
  justify-items: center;
  .column{
    max-width: 710px;
  }
  @media screen and (min-width: $break-desktop) {
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 40px), 1fr));
  }
}

.padded-wrapper {
  padding: 20px 10px;
  margin: auto;
  @media screen and (min-width: $break-mobile) {
    padding: 20px 30px;
  }
  @media screen and (min-width: $break-tablet) {
    padding: 30px 40px;
  }
}
.padded-vertical {
  padding: 24px 0;
}
.margin-top-25 {
  margin-top: 25px;
}
.margin-top-20 {
  margin-top: 20px;
}

.right {
  float: right;
  text-align: right;
}

.no-padding {
  padding: initial !important;
}

.mat-mdc-paginator {
  background-color: transparent !important;
  .mat-mdc-paginator-container {
    justify-content: flex-start;
  }
}

.mat-mdc-form-field {
  width: 100%;
}

.form-group {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  // button{
  //     width: 100%;
  // }
  .mat-mdc-form-field {
    margin-bottom: 0;
    .mat-mdc-form-field-infix {
      width: auto;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
form .mat-chip-list {
  display: flex;
}

.dropdown {
  display: block;
  bottom: 0px;
  box-sizing: border-box;
}

/** Datepicker increase font sizes when greater than mobile **/
@media screen and (min-width: $break-tablet) {
  .mat-datepicker-content-touch {
    .mat-mdc-button,
    .mat-mdc-fab,
    .mat-mdc-icon-button,
    .mat-mdc-mini-fab,
    .mat-mdc-raised-button,
    th,
    td {
      font-size: 20px;
    }
  }
}

.layout-three-rows {
  display: grid;
  grid-template-rows: 72px auto 72px;
  height: 100%;
  & > div,
  mat-horizontal-stepper {
    display: grid;
  }

.layout-nav-1 {
  justify-content: space-between;
  grid-auto-flow: column;
  padding: 0 20px;
  height: 72px;
  align-content: center;
  display: grid;
  &.absolute {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
  }
}

.layout-content-1 {
  padding: 40px 0;
  overflow-y: scroll;
  display: block;
}

.layout-footer-1 {
  height: $height-nav-component;
  width: 100%;
}
}


.item-info-content {
  display: flex;
  align-items: center;
  gap: 10px 12px;
  min-height: 36px;
  flex-wrap: wrap;
  justify-content: space-between;
  .param {
    display: flex;
    gap: 4px;
    flex-flow: wrap;
    align-items: center;
    .param-data {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.item-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.collaboration-item,
.bill-item {
  padding: 25px !important;
  border-radius: 25px !important;
  margin-bottom: 20px !important;
  box-shadow: initial !important;
  .item-header {
    cursor: pointer;
    justify-content: space-between;
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
    .mat-card-header-text {
      margin: initial;
    }
  }
  .mat-mdc-card-actions {
    margin-left: initial;
    margin-right: initial;
    padding: initial;
  }
  .mat-caption {
    padding: 0 5px;
  }
}

.mat-stepper-horizontal {
  &.no-header {
    .mat-horizontal-stepper-header-container {
      visibility: hidden !important;
    }
  }
  .mat-horizontal-stepper-header-container {
    max-width: calc(100% - 250px);
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    justify-content: center;
    visibility: hidden;
    @media screen and (min-width: $break-mobile) {
      visibility: initial;
    }

    .mat-stepper-horizontal-line {
      display: none;
    }
    .mat-horizontal-stepper-header {
      flex-direction: row-reverse;
      .mat-step-label-selected::before {
        content: "";
        display: block;
        width: 55%;
        height: 3px;
        top: 69px;
        position: absolute;
      }
      .mat-step-icon {
        background-color: initial;
        line-height: 24px;
        font-size: 12px;
        font-weight: 600;
        margin-left: 8px;
        display: none;
      }
      .mat-step-icon-state-error {
        display: inline-block;
      }
    }
  }
}

.guided-tour-spotlight-overlay {
  border-radius: 8px;
  transition: 0.2s all;
}

.grecaptcha-badge {
  visibility: hidden;
}

.clear-tags-button {
  cursor: pointer;
  display: inline;
  white-space: nowrap;
}

.actions-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.table-generator {
  .mat-mdc-card-content {
    padding: 20px;
    display: flex;
    margin: 0;
    justify-content: space-between;
    align-items: center;
  }
  .mat-mdc-card-footer {
    background-color: $colorSoftOrange;
    padding: 10px 20px;
    display: flex;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    .item-label {
      color: $colorGrey;
    }
    .total-and-action {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}

* {
  &[hidden] {
    display: none !important;
  }
}

.auth-container {
  margin: auto;
  .auth-content {
    .main-title {
      margin-bottom: 24px;
      h3 {
        margin: initial;
      }
      .subtitle {
        font-size: 14px;
      }
    }
  }
}

.mat-divider {
  margin: 22px 0;
}

.charts-wrapper {
  .mat-mdc-card {
    overflow: visible;
  }
  app-chart-empty-slate {
    text-align: center;
  }
}

td.mat-column-amount,
td.mat-column-subtotal {
  font-weight: bold;
}

.mat-mdc-tooltip-trigger {
  cursor: pointer;
}

.svg-icon-15 {
  top: 3px;
  position: relative;
}

.form-wrapper {
  margin-top: 15px;
}
.org-name-text {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

nav .mat-mdc-tab-link-container {
  flex-grow: 0;
}

// Decorate branding on Chat
[class^="WidgetBranding"] {
  opacity: 0 !important;
  position: absolute !important;
}

.partner-item {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 10px;
  padding: 10px 0;
  line-height: 1.4em;
  .partner-item-group {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    &:last-child {
      text-align: right;
    }
  }
  .workfield {
    padding: 2px 5px;
    text-align: center;
    display: inline-block;
    margin: 3px;
    font-size: 12px;
    border-radius: 20px;
    line-height: normal;
  }
  .name {
    display: block;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20ch;
    white-space: nowrap;
  }
  .email {
    font-weight: 500;
  }
  .country {
    text-align: right;
  }
}

.partner-avatar {
  display: inline-block;
  img.avatar {
    height: 32px;
    width: 32px;
  }
}

.search-bar {
  width: 100%;
  shared-table-search {
    mat-form-field {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          height: 36px;
        }
        .mat-mdc-form-field-infix {
          font-size: 16px;
          padding-top: 6px;
          padding-bottom: 6px;
          min-height: initial;
        }
      }
      .mat-mdc-form-field-subscript-wrapper {
        display: none!important;
      }
      .mat-mdc-form-field-icon-suffix,
      .mat-mdc-form-field-icon-prefix {
        .mdc-icon-button {
          height: 36px;
          width: 36px;
          padding: 6px;
        }
      }
    }
  }
}

// General settings for tables
.table-wrapper {
  overflow: auto;
  direction: rtl;
  table {
    direction: ltr;
  }
}

.table-set-wrapper {
  .multi-select-notice {
    padding: 20px;
    color: white;
    transition: all 0.5s ease;
  }
  .table-set-header {
    padding: 20px 20px 0 20px;
  }


  .table-set-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    overflow: hidden;
    position: relative;
  
    .table-set-group {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      gap: 8px;
      width: 100%;
  
      .table-set-item.search {
        flex-grow: 3;
  
        &.handset {
          flex-grow: 1;
          // width: 100%;
        }
      }
    }
  
    .table-set-item-group-right {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-left: auto;
      flex-wrap: nowrap;
  
      .table-set-item {
        display: flex;
        align-items: center;
      }
    }
  }
  
  .table-set-footer {
    padding: 4px 0 4px 0;
  }
}


.mat-mdc-table {
  .expandable-row{
    height: 0!important;
    .mat-column-expandedDetail{
      box-shadow: inset 0px 5px 5px -5px rgba(0, 0, 0, 0.12),
                  inset 0px -5px 5px -5px rgba(0, 0, 0, 0.12);
    }
    .element-detail{
      transition: padding 120ms ease-in-out;
    }
    &.expanded-row{
      .element-detail{
        padding: 16px;
      }
    }
  }
  .element-detail {
    overflow: hidden;
    max-width: 500px;
    margin: auto;
    .element-detail-wrapper {
      // margin: 20px;
      display: flex;
      gap: 20px;
      flex-direction: column;
      width: 100%;
      .item-info {
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
        flex-direction: column;
        .param {
          display: flex;
          padding: 5px;
          gap: 5px;
          justify-content: space-between;
        }
      }
    }
  }

  &:not(.vertical-table) {
    th.mat-mdc-header-cell {
      &:not(:first-child):not(:last-child) {
        padding: 10px;
      }
    }

    .mat-mdc-row {
      &:not(:first-child):not(:last-child).mat-mdc-cell {
        padding: 0 10px;
      }
      .mat-mdc-cell:last-of-type {
        &.actions-cell {
          white-space: nowrap;
          padding-right: 13px;
          text-align: right;
        }
      }
      .clickable-cell .cell-wrapper {
        border-radius: 32px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &.vertical-table {
    width: 100%;
    border-collapse: collapse;

    // Caso cuando no hay acción
    &.no-action {
      tbody {
        tr:not(.detail-row) {
          padding-right: 0;
          td {
            padding-right: 15px;
            padding-left: 30vw;
          }

          .actions-cell {
            display: none;
          }
        }
      }
    }

    thead {
      display: none;
    }

    tbody {
      tr:not(.detail-row) {
        display: block;
        height: initial;
        position: relative;
        padding: 20px 50px 20px 16px;

        td {
          display: flex;
          align-items: center;
          position: relative;
          min-height: 40px;
          justify-content: flex-end;
          text-align: right;
          padding: initial;
          max-width: 100%;
          &:not(.actions-cell) > * {
            max-width: 60%;
          }
          &:not(.actions-cell):before {
            content: attr(data-table-header);
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 40%;
            padding-left: 15px;
            font-weight: bold;
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
          }

          &.actions-cell {
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            height: 100%;
            width: 48px;
            padding: initial;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }

          .cell-wrapper {
            width: initial;
            justify-content: flex-end;
            padding: initial;
            max-width: 75%;
          }
        }
      }
    }
  }
}

mat-card.mat-mdc-card {
  border-style: hidden;
  box-shadow: initial;
  padding: 8px 16px;
  @media (min-width: $break-mobile) {
      padding: 20px 32px;
  }
  .calc-highlight {
      .notice {
          font-size: small;
          padding: 8px;
          border-radius: 8px;
      }
  }
  .calc-subitem-wrapper {
      padding: 8px;
      border-radius: 8px;
      .calc-subitem {
          gap: 5px;
          display: flex;
          justify-content: space-between;
          .mat-icon {
              width: 35px;
          }
          .subitem-label {
              gap: 5px;
              display: flex;
              min-width: 0;
              align-items: center;
              .text {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
              }
          }
      }
  }
  .mdc-button{
    line-height: initial;
  }
  .mat-mdc-card-title {
      font-size: 18px;
      padding-top: 10px;
      line-height: 32px;
  }
  &.radio-checkbox-right {
      border: 1px solid transparent;
      &::after {
          font-family: "Material Icons";
          position: absolute;
          font-size: 26px;
          content: "radio_button_unchecked";
          top: calc(50% - 13px);
          left: calc(100% - 70px);
      }
      &.selected {
          &::after {
              content: "radio_button_checked";
          }
      }
  }
}

.quick-actions-container {
  .quick-actions {
    .quick-action {
      transition: background 0.1s ease-in-out;
    }
  }
}

.mat-mdc-dialog-container {
  .mat-mdc-dialog-surface {
    padding: 5px;
    .mat-mdc-dialog-actions {
      flex-flow: nowrap;
      justify-content: center;
      gap: 10px;
      padding: 0 24px 20px 24px;
      & > .mat-mdc-button-base {
          width: 100%;
      }
    }
  }
  .mat-mdc-dialog-content {
    text-align: center;
    padding-top: 4px !important;
  }
  
}

.role {
  position: relative;
  & > span {
      font-weight: 500;
      font-size: 12px;
      padding: 4px 12px;
      cursor: pointer;
      border-radius: 25px;
      white-space: nowrap;
  }
}

.cdk-overlay-backdrop {
  backdrop-filter: blur(2px) brightness(0.7);
}

.shrinkable-button {
  &.handset{
    min-width: initial;
    mat-icon{
      margin: initial;
    }
  }
}

app-cookie-consent {
  display: none;
}