/* For use in src/lib/core/theming/_palette.scss */
$shoutly-blue: (
  50 : #EAEFF7,
  100 : #b0c5ea,
  200 : #1c283f,
  300 : #4b7ad0,
  400 : #3469c8,
  500 : #052033,
  600 : #041b2c,
  700 : #031624,
  800 : #02111d,
  900 : #010c16,
  A100 : #e0eafd,
  A200 : #96add7,
  A400 : #316fe0,
  A700 : #2d65ca,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$shoutly-blue-dark: (
  50 : #0d47a1,  
  100 : #1565c0,
  200 : #1976d2,
  300 : #1e88e5,
  400 : #2196f3,
  500 : #42a5f5,
  600 : #64b5f6,
  700 : #90caf9,
  800 : #bbdefb,
  900 : #e3f2fd,
  A100 : #2962ff,
  A200 : #2979ff,
  A400 : #448aff,
  A700 : #82b1ff,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #000000,
    500 : #ffffff,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #000000,
    A700 : #000000,
  )
);

$shoutly-red: (
    50 : #fae6e6,
    100 : #f5cccc,
    200 : #eb9999,
    300 : #e68080,
    400 : #e06666,
    500 : #cc0000,
    600 : #8f0000,
    700 : #660000,
    800 : #3d0000,
    900 : #290000,
    A100 : #ffffff,
    A200 : #ffc4bb,
    A400 : #f47560,
    A700 : #e86752,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #757F8B,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$shoutly-yellow: (
  50 : #f0e68c,
  100 : #e6d85a,
  200 : #dcd226,
  300 : #d2c712,
  400 : #c8b80e,
  500 : #beb10b,
  600 : #b4a807,
  700 : #a99f04,
  800 : #9e9500,
  900 : #8b8400,
  A100 : #e6d85a,
  A200 : #dcd226,
  A400 : #c8b80e,
  A700 : #a99f04,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);


$grey-palette: (
  50: #e8e8e8,
  100: #f1e8e4,
  200: #606060,
  300: #494949,
  400: #333333,
  500: #052033,
  600: #191919,
  700: #161616,
  800: #141414,
  900: #030303,
  A100: #c9c9c9,
  A200: #969696,
  A400: #707070,
  A700: #383838,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: #fdfdfd,
    600: #F7F7F8,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  )
);

$turnconsulting-primary: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #e7f5f4,
    300 : #b5e0dd,
    400 : #a0d7d3,
    500 : #8acec9,
    600 : #74c5bf,
    700 : #5fbcb5,
    800 : #4ab2aa,
    900 : #419c96,
    A100 : #ffffff,
    A200 : #f4fdfc,
    A400 : #a5e6e1,
    A700 : #98d9d4,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000, //#4a5d70,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$shoutly-bluegray: (
    50 : #d6dde4,
    100 : #a7b6c5,
    200 : #869aaf,
    300 : #5e778f,
    400 : #52677c,
    500 : #46586a,
    600 : #3a4958,
    700 : #2e3945,
    800 : #212a33,
    900 : #151b20,
    A100 : #c4d7eb,
    A200 : #77a4d2,
    A400 : #49719a,
    A700 : #4b657e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$shoutly-marginalengreen: (
    50 : #ffffff,
    100 : #e3f1ed,
    200 : #bddfd4,
    300 : #8dc7b5,
    400 : #79bda8,
    500 : #64b39b,
    600 : #52a78d,
    700 : #48927b,
    800 : #3e7e6a,
    900 : #346959,
    A100 : #ffffff,
    A200 : #bff1e2,
    A400 : #79d1b6,
    A700 : #70c1a8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$shoutly-marginalenred: (
    50 : #ffffff,
    100 : #f3cdd0,
    200 : #e9a0a4,
    300 : #dc666d,
    400 : #d64d56,
    500 : #d0343e,
    600 : #ba2b34,
    700 : #a1252d,
    800 : #892026,
    900 : #701a1f,
    A100 : #ffffff,
    A200 : #fba2a7,
    A400 : #e94e58,
    A700 : #da434d,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$shoutly-marginalenyellow: (
    50 : #ffffff,
    100 : #fffffd,
    200 : #fbf8c9,
    300 : #f6f086,
    400 : #f4ed69,
    500 : #f2e94d,
    600 : #f0e530,
    700 : #eee214,
    800 : #d4c90f,
    900 : #b7ae0d,
    A100 : #ffffff,
    A200 : #fffdd9,
    A400 : #fff773,
    A700 : #f8f061,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);