@use "@angular/material" as mat;
@use "sass:map";
@use "sass:math";
@use "sass:color";
@import "./../styles/variables.scss";

// Helpers
@mixin box-shadow($h-offset, $v-offset, $blur-radius, $shadow-color) {
    box-shadow: $h-offset $v-offset $blur-radius $shadow-color;
}

/// This mixin styles the shoutly logo based on the provided theme configuration.
/// It allows for alternate color styling.
///
/// @param {Map} $config-or-theme - The theme configuration.
/// @param {Boolean} $use-alternate-color - If true, use the alternate color for styling.
///
/// @example
///   @include shoutly-logo($my-theme, true);
@mixin shoutly-logo($config-or-theme, $use-alternate-color: false) {
    $color-config: mat.m2-get-color-config($config-or-theme);

    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);
    $primary: map.get($color-config, primary);

    $is-dark-theme: map.get($color-config, is-dark);

    // Determine the text color based on the alternate color flag and theme configuration
    $text-color: mat.m2-get-color-from-palette($foreground, text);

    .main-logo {
        fill: $text-color;
        &.alt-color {
            // fill:  mat.get-color-from-palette($primary, "700-contrast");
            fill: mat.m2-get-color-from-palette($background, background);
        }

        @if not $use-alternate-color and not $is-dark-theme {
            &.opened {
                fill: mat.m2-get-color-from-palette($background, background);
            }
        }
    }
}

@mixin shoutly-menues($config-or-theme) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $primary: map.get($color-config, primary);
    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);
    $warn: map.get($color-config, warn);

    $is-dark-theme: map.get($color-config, is-dark);

    .hamburger-button {
        .bar1,
        .bar2,
        .bar3 {
            border: 1px solid mat.m2-get-color-from-palette($foreground, text);
        }

        &.open {
            .bar1,
            .bar2,
            .bar3 {
                border: 1px solid;
                border-color: mat.m2-get-color-from-palette($foreground, text);
                background-color: mat.m2-get-color-from-palette($foreground, text);
            }
        }
    }

    .dropdown {
        background-color: mat.m2-get-color-from-palette($background, card);
    }

    .border-solid-soft {
        border: 1px solid rgba(mat.m2-get-color-from-palette($foreground, "secondary-text"), 0.34);
    }

    .payout-information {
        padding: 7px 10px;
        background-color: mat.m2-get-color-from-palette($warn, 100);
        margin-bottom: 10px;
        border-radius: 8px;
        color: mat.m2-get-color-from-palette($foreground, "secondary-text");
    }

    .box .icon {
        $icon-background-color: mat.m2-get-color-from-palette($foreground, text);
        background-color: rgba($icon-background-color, 3%);
    }

    .mat-calendar-content {
        button.highlight {
            .mat-calendar-body-cell-content {
                background-color: mat.m2-get-color-from-palette($primary, 100);
            }
        }
    }

    .mat-mdc-menu-panel {
        border-radius: 8px !important;
        .mat-mdc-menu-content {
            padding: initial;
            .mdc-list-item {
                &:hover {
                    .mat-mdc-list-item-icon,
                    .mdc-list-item__primary-text {
                        color: mat.m2-get-color-from-palette($primary, 800);
                    }
                }
                .mat-mdc-list-item-icon,
                .mdc-list-item__primary-text {
                    color: mat.m2-get-color-from-palette($foreground, text);
                }
            }
        }
    }

    .mdc-menu-surface {
        min-width: 250px;
    }
}

@mixin shoutly-tabs($config-or-theme) {
    $color-config: mat.m2-get-color-config($config-or-theme);

    $primary: map.get($color-config, primary);
    $warn: map.get($color-config, warn);
    $foreground: map.get($color-config, foreground);

    .mat-mdc-tab-links {
        .mat-mdc-tab-link.mat-mdc-focus-indicator {
            min-width: initial;
            padding-left: 0;
            padding-right: 0;
            margin: 0 16px;
            opacity: 1;
            text-decoration: none;
            &.mdc-tab--active {
                .mdc-tab__content {
                    .mdc-tab__text-label {
                        font-weight: 600;
                    }
                }
            }
            .mdc-tab__content {
                .mdc-tab__text-label {
                    font-weight: 300;
                    font-size: 16px;
                    letter-spacing: initial;
                    color: mat.m2-get-color-from-palette($foreground, text);
                }
            }
            .mdc-tab-indicator {
                justify-content: flex-start;
                .mdc-tab-indicator__content {
                    width: 75%;
                    background-color: mat.m2-get-color-from-palette($foreground, text);
                    border-color: mat.m2-get-color-from-palette($foreground, text);
                }
            }
            .mdc-tab__ripple::before {
                // We don't want background color on the tab links
                background-color: initial !important;
            }
        }
    }

    .mat-mdc-tab-header-pagination-before .mat-mdc-tab-header-pagination-chevron,
    .mat-mdc-tab-header-pagination-after .mat-mdc-tab-header-pagination-chevron {
        border-color: mat.m2-get-color-from-palette($foreground, text);
    }
}

@mixin shoutly-text-defaults($config-or-theme) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);
    $primary: map.get($color-config, primary);

    a {
        color: mat.m2-get-color-from-palette($foreground, text);
        text-decoration: underline;
        &.link {
            cursor: pointer;
        }
    }
    .primary-text {
        color: mat.m2-get-color-from-palette($foreground, text);
    }
    .secondary-text {
        color: mat.m2-get-color-from-palette($foreground, secondary-text);
    }

    .alert-text {
        color: $colorOrange;
    }
}

@mixin shoutly-buttons-theme($config-or-theme) {
    $color-config: mat.m2-get-color-config($config-or-theme);

    $primary: map.get($color-config, primary);
    $warn: map.get($color-config, warn);
    $background: map.get($color-config, background);
    $foreground: map.get($color-config, foreground);
    $is-dark-theme: map.get($color-config, is-dark);

    // .icon {
    //     svg > path {
    //         stroke: mat.get-color-from-palette($foreground, text);
    //         @if $is-dark-theme {
    //             stroke: mat.get-color-from-palette($primary, "500-contrast");
    //         }

    //     }
    // }

    .svg-icon {
        path.primary {
            &.stroke {
                stroke: mat.m2-get-color-from-palette($foreground, text);
                @if $is-dark-theme {
                    stroke: mat.m2-get-color-from-palette($primary, "500-contrast");
                }
                // stroke: mat.get-color-from-palette($foreground, text);
            }
            &.fill {
                fill: mat.m2-get-color-from-palette($foreground, text);
                @if $is-dark-theme {
                    fill: mat.m2-get-color-from-palette($primary, "500-contrast");
                }
                // fill: mat.get-color-from-palette($foreground, text);
            }
        }
    }

    .font-color {
        &.font-color-warn {
            color: mat.m2-get-color-from-palette($warn, 500);
        }
    }

    .mat-mdc-fab-base {
        box-shadow: initial;
    }

    button {
        &.mdc-button {
            &.mat-mdc-button-base {
                text-transform: uppercase;
            }
        }
    }

    .mat-stepper-horizontal {
        background-color: transparent !important;
        &.disabled-step-header-actions {
            .mat-step-header {
                pointer-events: none;
            }
        }
        .mat-horizontal-stepper-header-container {
            .mat-step-header {
                .mat-step-label {
                    &.mat-step-label-selected,
                    &.mat-step-label-active,
                    &.mat-step-label-error {
                        // color: mat.get-color-from-palette($primary, 800);
                        font-weight: 500;
                    }
                    &.mat-step-label-active {
                        &.mat-step-label-selected {
                            &::before {
                                background-color: mat.m2-get-color-from-palette($foreground, text);
                            }
                        }
                    }
                }
            }
        }
        .mat-horizontal-content-container {
            padding: initial;
        }
    }

    .mat-button-toggle-group {
        font-size: 14px;
        border-radius: 24px !important;
        box-shadow: initial;
        border: initial;
        background-color: mat.m2-get-color-from-palette($background, card);
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-weight: 500;
        .mat-button-toggle {
            border-radius: 24px;
            background-color: transparent;
            color: mat.m2-get-color-from-palette($foreground, "secondary-text");
            padding: 5px;
            .mat-button-toggle-button {
                .mat-button-toggle-label-content {
                    line-height: initial;
                    padding: 0 8px;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: smaller;
                }
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-toggle-checked {
            background-color: mat.m2-get-color-from-palette($primary);
            color: mat.m2-get-color-from-palette($primary, "500-contrast");
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
            .mat-button-toggle-focus-overlay {
                border: initial !important;
            }
        }
    }

    button.collapse-layout-button.mat-mdc-mini-fab {
        background-color: mat.m2-get-color-from-palette($background, card);
        color: mat.m2-get-color-from-palette($foreground, text);
    }

    shared-language-selector {
        .language-selector {
            @if $is-dark-theme {
                mat-icon {
                    color: white;
                }
                .lang-item > img {
                    @include box-shadow(0px, 0px, 10px, rgba(255, 255, 255, 0.25));
                }
            }

            &.dark-contrast {
                mat-icon {
                    color: white;
                }
                .lang-item > img {
                    @include box-shadow(0px, 0px, 10px, rgba(255, 255, 255, 0.25));
                }
            }
        }
    }
    @if $is-dark-theme {
        shared-expansion-arrow-rotate {
            .mat-icon {
                color: mat.m2-get-color-from-palette($foreground, text);
            }
        }
    }
}

@mixin shoutly-layout-components($config-or-theme, $roundnessMap) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $primary: map.get($color-config, primary);
    $warn: map.get($color-config, warn);
    $background: map.get($color-config, background);
    $typography-config: mat.m2-get-typography-config($config-or-theme);
    $accent: map.get($color-config, accent);
    $foreground: map.get($color-config, foreground);
    $accent-color: mat.m2-get-color-from-palette($accent);

    $background-palette: map.get(mat.m2-get-color-config($config-or-theme), "background");
    $foreground-palette: map.get(mat.m2-get-color-config($config-or-theme), "foreground");

    body {
        background-color: mat.m2-get-color-from-palette($background-palette, "background");
        color: mat.m2-get-color-from-palette($foreground-palette, "text");
    }

    .title-component-header {
        color: mat.m2-get-color-from-palette($foreground, text);
    }

    .layout-nav-1 {
        background-color: mat.m2-get-color-from-palette($background, card);
    }
    .layout-footer-1 {
        background-color: mat.m2-get-color-from-palette($background, card);
    }

    app-horizontal-user-cabinet {
        @extend .mdc-button;
        .active {
            background-color: darken(mat.m2-get-color-from-palette($background, card), 5%);
        }
    }

    .auth-layout-content-container {
        border-radius: map-get($roundnessMap, "cards") map-get($roundnessMap, "cards") 0 0;
        background-color: mat.m2-get-color-from-palette($background, background) !important;
    }
}

@mixin shoutly-statuses($config-or-theme) {
    .status-wrapper {
        .status {
            // color: $colorGreenv2;
            background-color: transparent;
            &.orange,
            &.warning {
                background-color: #{$colorOrange} + 16;
                color: $colorOrange;
            }
            &.yellow {
                background-color: #{$colorYellowv1} + 16;
                color: $colorYellowv1;
            }
            &.red,
            &.error {
                background-color: #{$colorRed} + 16;
                color: $colorRed;
            }
            &.green,
            &.success {
                background-color: #{$colorGreenv2} + 16;
                color: $colorGreenv2;
            }
            &.grey,
            &.loading {
                background-color: #{$colorGrey} + 16;
                color: $colorGrey;
            }
            &.blue,
            &.info {
                background-color: #{$colorBlue} + 16;
                color: $colorBlue;
            }
        }
    }

    .item-number {
        &.pending {
            color: $colorOrange;
        }
        &.cancelled,
        &.not-billed,
        &.not-paid,
        &.bill-not-paid &.unpaid {
            color: $colorRed;
        }
        &.sent,
        &.active,
        &.accepted,
        &.ongoing,
        &.new,
        &.paid-out {
            color: $colorGreenv2;
        }
        &.finished,
        &.declined {
            color: $colorGrey;
        }
        &.payout-waiting {
            color: $colorBlue;
        }
    }
}

@mixin shoutly-card-item-collaboration($config-or-theme) {
    $color-config: mat.m2-get-color-config($config-or-theme);

    $primary: map.get($color-config, primary);
    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);

    .mat-caption {
        display: inline;
        color: mat.m2-get-color-from-palette($primary, 200);
    }

    .collab-report-preview > .reported-active {
        color: mat.m2-get-color-from-palette($foreground, secondary-text);
        background-color: mat.m2-get-color-from-palette($background, card);
        .value {
            color: mat.m2-get-color-from-palette($foreground, text);
        }
    }

    .top-bar-extra-info {
        background: mat.m2-get-color-from-palette($background, card);
        border-top: 1px solid $colorGreyv1;
        border-radius: 0 0 25px 25px;
        color: mat.m2-get-color-from-palette($foreground, text);
    }

    .grid-collaboration-item {
        &.action-required {
            border-top-color: $colorSofterOrange;
            outline-color: $colorOrange;
            .action-message {
                color: $colorOrange;
            }
        }
    }

    .action-required-message-top {
        background-color: $colorSofterOrange;
        box-shadow: 0 0 1px 1px $colorOrange;
        outline: 1px solid $colorOrange;
        color: $colorOrange;
    }
}

@mixin shoutly-user-item($config-or-theme) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $accent: map.get($color-config, accent);

    .partner-item {
        .workfield {
            background-color: mat.m2-get-color-from-palette($accent, 200);
            color: #2e5cb0;
        }
    }
}

@mixin shoutly-chart-components($config-or-theme) {
    $config: mat.m2-get-color-from-palette($config-or-theme);
    $foreground: map.get($config, foreground);

    .chart {
        fill: mat-color($foreground, text);
    }
}

@mixin shoutly-notifications($config-or-theme) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $primary: map.get($color-config, primary);
    $warn: map.get($color-config, warn);
    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);

    .notifications-icon {
        &.has-unread-notifications::after {
            border: 2px solid mat.m2-get-color-from-palette($background, background);
            background-color: mat.m2-get-color-from-palette($warn);
        }
    }

    .notifications-wrapper {
        color: map.get($foreground, text);
        .notification-item {
            background: mat.m2-get-color-from-palette($background, background);
            &:not(:last-child) {
                border-bottom: 1px solid darken(mat.m2-get-color-from-palette($background, background), 3%);
            }
            &.unread {
                background: mat.m2-get-color-from-palette($background, card);
            }
        }
        .notification-review {
            color: mat.m2-get-color-from-palette($warn);
        }
    }
}

@mixin shoutly-mat-cards($config-or-theme, $roundnessMap) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $typography-config: mat.m2-get-typography-config($config-or-theme);
    $warn: map.get($color-config, warn);
    $primary: map.get($color-config, primary);
    $accent: map.get($color-config, accent);
    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);
    $is-dark-theme: map.get($color-config, is-dark);

    // Declare custom color variables
    $colorSofterOrange: mat.m2-get-color-from-palette($accent, 100);
    $colorOrange: mat.m2-get-color-from-palette($accent, 500);

    @keyframes barridoDerecha {
        0% {
            background-position: 200% center;
        }
        100% {
            background-position: -200% center;
        }
    }

    .background-grey {
        background-color: #{map.get($foreground, "secondary-text") + "14"};
    }

    .auth-notice {
        padding: 10px;
        background-color: #{map.get($foreground, "secondary-text") + "14"};
        border-radius: 5px;
        margin: 10px 0;
        font-size: 0.9em;
        line-height: 1.4em;
    }

    .auth-error {
        padding: 10px;
        background-color: mat.m2-get-color-from-palette($warn, 100);
        border-radius: 5px;
        margin: 10px 0;
    }

    .highlight-notice {
        padding: 5px;
        border-radius: map-get($roundnessMap, "cards");
        border: 1px solid;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        margin-bottom: 18px;

        &.alert {
            background-color: $colorSofterOrange;
            color: $colorOrange;
            border-color: $colorOrange;
        }

        .notice {
            font-size: small;
        }
    }

    mat-card.mat-mdc-card {
        border-radius: map-get($roundnessMap, "cards");
        background-color: mat.m2-get-color-from-palette($background, card);

        &.dashboard-item {
            mat-card-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .calc-highlight {
            background-color: mat.m2-get-color-from-palette($primary, 50);

            @if $is-dark-theme {
                background-color: mat.m2-get-color-from-palette($primary, 900);
            }

            .notice {
                background-color: lighten(mat.m2-get-color-from-palette($background, card), 5%);
            }
        }

        .calc-subitem-wrapper {
            background-color: darken(mat.m2-get-color-from-palette($primary, 50), 5%);
        }

        .label,
        .secondary-value {
            color: map.get($foreground, "secondary-text");
        }

        .select-user-type-item-icon {
            svg > path {
                stroke: mat.m2-get-color-from-palette($warn);
            }
        }

        &.radio-checkbox-right {
            &.radio-checkbox-color.warn {
                &::after {
                    color: mat.m2-get-color-from-palette($warn);
                }
            }

            &.selected {
                border: 1px solid mat.m2-get-color-from-palette($foreground, text);
            }
        }

        .attest-needed {
            background-color: $colorSofterOrange;
            color: $colorOrange;
        }
    }


    .quick-actions-container {
        h3 {
          color: mat.m2-get-color-from-palette($background, card);
        }
    
        .quick-actions {
          .quick-action {
            border: 1px solid rgba(mat.m2-get-color-from-palette($foreground, secondary-text), 0.09);
            border-radius: map-get($roundnessMap, "buttons");
            background-color: mat.m2-get-color-from-palette($background, card);
    
            &:hover {
              background-color: darken(mat.m2-get-color-from-palette($background, card), 5%);
            }
    
            .quick-action-icon {
                background-color: mat.m2-get-color-from-palette($background, card);
                border: 1px solid rgba(mat.m2-get-color-from-palette($foreground, secondary-text), 0.09);
            }
    
            .quick-action-next-arrow {
              button {
                mat-icon {
                  color: mat.m2-get-color-from-palette($primary, 500);
                }
              }
            }
          }
        }
      }

    .skeleton {
        background-color: transparent;
        border-radius: map-get($roundnessMap, "buttons");
        display: flex;
        height: 2em;
        width: auto;
        background: linear-gradient(
            90deg,
            mat.m2-get-color-from-palette($foreground, divider) 25%,
            lighten(mat.m2-get-color-from-palette($foreground, divider), 25%) 50%,
            mat.m2-get-color-from-palette($foreground, divider) 75%
        );
        background-size: 200% 100%;
        animation: barridoDerecha 1.5s linear infinite;

        &.circle {
            width: 26px;
        }
    }

    .card-outline-error {
        box-shadow: 1px -66px 52px -24px rgba(255, 0, 0, 0.34) inset;
        -webkit-box-shadow: 1px -66px 52px -24px rgba(255, 0, 0, 0.34) inset;
        -moz-box-shadow: 1px -66px 52px -24px rgba(255, 0, 0, 0.34) inset;
    }

    .provider-select {
        border-radius: map-get($roundnessMap, "cards");
        background-color: mat.m2-get-color-from-palette($background, card);
    }

    .invitation-container{
        border-radius: map-get($roundnessMap, "cards");
        background-color: mat.m2-get-color-from-palette($background, card);
    }
  
}

@mixin shoutly-mat-tables($config-or-theme, $roundnessMap) {
    // Color config
    $color-config: mat.m2-get-color-config($config-or-theme);
    $warn: map.get($color-config, warn);
    $background: map.get($color-config, background);
    $primary: map.get($color-config, primary);
    $foreground: map.get($color-config, foreground);

    .table-set-wrapper {
        background-color: mat.m2-get-color-from-palette($background, card);
        border-radius: map-get($roundnessMap, "cards");
        .multi-select-notice {
            background-color: mat.m2-get-color-from-palette($primary, 500);
        }
        .table-set-header {
            border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }
        .table-set-footer {
            border-top: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        }
    }

    .mat-mdc-table {
        .expandable-row {
            &.expanded-row {
                border-bottom: 8px solid mat.m2-get-color-from-palette($background, background);
            }
        }

        &.expandable .mat-mdc-row {
            &:nth-child(4n + 1) {
                background-color: #{darken(mat.m2-get-color-from-palette($background, app-bar), 1%)};
            }
        }
        &:not(.expandable) .mat-mdc-row {
            &:nth-child(2n + 1) {
                background-color: #{darken(mat.m2-get-color-from-palette($background, app-bar), 1%)};
            }
        }
        .mdc-data-table__header-cell {
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
        }
        .mdc-data-table__cell {
            color: mat.m2-get-color-from-palette($foreground, text);
            text-overflow: ellipsis;
            border-bottom-width: 0 !important;
        }
        &:not(.vertical-table) {
            .mat-mdc-row {
                .clickable-cell .cell-wrapper {
                    &:hover {
                        background-color: mat.m2-get-color-from-palette($warn, 100, 0.3);
                    }
                }
            }
        }
    }

    .table-subtotal {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: mat.m2-get-color-from-palette($primary, 500);
        padding: 15px 20px;
        color: white;
        box-sizing: border-box;
    }

    .mat-mdc-paginator {
        .mat-mdc-paginator-container {
            color: mat.m2-get-color-from-palette($foreground, secondary-text);
            mat-form-field.mat-mdc-form-field {
                &.form-field-highlight {
                    background-color: #{darken(mat.m2-get-color-from-palette($background, app-bar), 1%) + "78"};
                }
                .mat-mdc-form-field-flex {
                    .mdc-notched-outline {
                        .mdc-notched-outline__leading,
                        .mdc-notched-outline__trailing {
                            display: none;
                        }
                    }
                }
                .mat-mdc-form-field-subscript-wrapper {
                    display: none;
                }
            }
        }
    }

    .timeline{
        &.finished{
            .timeline-row {
                .event {
                    .ball{
                        animation: none;
                    }
                    .ball,
                    .line {
                        background: $colorGreen;
                    }
                }
            }
        }
        &:not(.finished){
            .timeline-row {
                &.is-last {
                    .event {
                        .ball {
                            animation: bounce-glow 1s ease-in-out infinite alternate;
                            background: mat.m2-get-color-from-palette($primary);
                        }
                    }
                }
                &.past {
                    .event {
                        .ball,
                        .line {
                            background: mat.m2-get-color-from-palette($primary);
                        }
                    }
                }
                &.future {
                    .event {
                        .ball,
                        .line {
                            background: $colorGreyv1;
                        }
                    }
                }
            }
        }



        @keyframes bounce-glow {
            0% {
                box-shadow: 0 0 0 4px color.change(mat.m2-get-color-from-palette($primary, 600), $alpha: 0);
            }
            100% {
                box-shadow: 0 0 0px 4px color.change(mat.m2-get-color-from-palette($primary, 600), $alpha: 0.4);
            }
        }
    }
}

@mixin shoutly-sidenav($config-or-theme, $roundnessMap, $alt-color: false) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $primary: map.get($color-config, primary);
    $background: map.get($color-config, background);
    $foreground: map.get($color-config, foreground);
    $is-dark-theme: map.get($color-config, is-dark);
    $alt-drawer-color: mat.m2-get-color-from-palette($background, background);

    $drawer-color: mat.m2-get-color-from-palette($background, card);

    $text-color: if(
        $alt-color,
        mat.m2-get-color-from-palette($foreground, text),
        mat.m2-get-color-from-palette($primary, "500-contrast")
    );

    .avatar {
        background-color: mat.m2-get-color-from-palette($primary, A200);
    }

    .mini-text-carousel {
        color: white;
    }

    .custom-sidenav {
        background-color: $drawer-color;
        box-shadow: 1px 0px 10px mat.m2-get-color-from-palette($foreground, divider);
    }



    .org-switcher-wrapper {
        border-top: 1px solid mat.m2-get-color-from-palette($foreground, divider);
        color: $text-color;
        background-color: $drawer-color;
    }

    .org-switcher-expanded {
        background-color: darken($drawer-color, 5%);
        color: $text-color;
        border-radius: map-get($roundnessMap, "cards") map-get($roundnessMap, "cards") 0 0;
    }

    .sidebar-menu-items-container {
        padding: 0 12px;
        box-sizing: border-box;
        .mat-mdc-list-item {
            padding-top: 3px;
            margin-bottom: 3px;
            border-radius: map-get($roundnessMap, "buttons") !important;
            .mdc-list-item__content {
                .mat-mdc-list-item-title {
                    font-weight: 500;
                    color: $text-color;
                }
            }
            .mat-mdc-list-item-icon {
                margin-right: 16px;
                text-align: center;
                & > * {
                    color: $text-color;
                }
            }
            &:hover {
                background-color: rgba($color: #000000, $alpha: 0.1);
                cursor: pointer;
            }
            &.active {
                background-color: mat.m2-get-color-from-palette($primary);
                color: mat.m2-get-color-from-palette($primary, "500-contrast");

                .mdc-list-item__content .mat-mdc-list-item-title {
                    color: mat.m2-get-color-from-palette($primary, "500-contrast");
                }
                .mat-mdc-list-item-icon > * {
                    color: mat.m2-get-color-from-palette($primary, "500-contrast");
                }
            }
        }
        .sidebar-category {
            .label {
                color: mat.m2-get-color-from-palette($foreground, "secondary-text");
            }
            .divider {
                border-color: mat.m2-get-color-from-palette($foreground, "secondary-text");
            }
        }
    }

    .content-header.sidebar-state-closed {
        background-color: $drawer-color;
    }
}

@mixin shoutly-svg($config-or-theme) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $typography-config: mat.m2-get-typography-config($config-or-theme);
    $foreground: map.get($color-config, foreground);
    $primary: map.get($color-config, primary);

    .empty-slate {
        font-size: mat.m2-font-size($typography-config, "body-1");
        font-family: mat.m2-font-family($typography-config);
        .blank-slate-icon .stroke {
            stroke: mat.m2-get-color-from-palette($foreground, text);
        }
    }

    div > svg {
        path.primary {
            &.stroke {
                stroke: mat.m2-get-color-from-palette($primary);
            }
            &.fill {
                fill: mat.m2-get-color-from-palette($primary);
            }
        }
        path.secondary {
            &.stroke {
                stroke: lighten(mat.m2-get-color-from-palette($primary, 100), 10%);
            }
            &.fill {
                fill: lighten(mat.m2-get-color-from-palette($primary, 100), 10%);
            }
        }
    }
}

@mixin shoutly-dialogs($config-or-theme, $roundnessMap) {
    $color-config: mat.m2-get-color-config($config-or-theme);

    $typography-config: mat.m2-get-typography-config($config-or-theme);
    $primary: map.get($color-config, primary);
    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);

    .mat-mdc-dialog-container {
        .mat-mdc-dialog-surface {
            border-radius: map-get($roundnessMap, "cards") !important;
            // .mat-mdc-dialog-title {
            //     color: mat.get-color-from-palette($primary, 800);
            // }
        }
        .mat-mdc-dialog-content {
            font-family: mat.m2-font-family($typography-config);
        }
    }

    // @finsweet/cookie-consent has been implemented
    app-cookie-consent {
        .banner {
            border: 2px solid mat.m2-get-color-from-palette($foreground, divider);
            background-color: mat.m2-get-color-from-palette($background, card);
        }
        .preferences {
            form#cookie-preferences {
                background-color: mat.m2-get-color-from-palette($background, card);
            }
            .overlay {
                background-color: rgba(0, 0, 0, 0.8);
            }
            .option {
                border-bottom: 1px solid mat.m2-get-color-from-palette($foreground, divider);
            }
        }
    }
}

@mixin shoutly-accordions($config-or-theme, $roundnessMap) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $primary: map.get($color-config, primary);
    $foreground: map.get($color-config, foreground);

    .mat-accordion {
        .mat-expansion-panel-header.mat-expanded:focus,
        .mat-expansion-panel-header.mat-expanded:hover {
            background: none;
        }
        .mat-expansion-panel {
            &:not(:last-child):not(.mat-expansion-panel-spacing) {
                margin-bottom: 20px;
            }
            border-radius: map-get($roundnessMap, "cards") !important;
            &.mat-expanded {
                border: 1px solid mat.m2-get-color-from-palette($foreground, text);
            }
            &.disabled {
                pointer-events: none;
                opacity: 0.5; // Puedes ajustar esto según tus necesidades
            }
            .mat-expansion-panel-header {
                height: auto;
                .mat-content {
                    display: block;
                    padding: 20px 0;
                    .mat-expansion-panel-header-title {
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 500;
                    }
                }
            }
            .mat-expansion-panel-body {
                padding: 0 16px 16px;
            }

            &.no-padding {
                .mat-expansion-panel-body {
                    padding: 0;
                }
            }
        }
        .panel-title-flex {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }
}

@mixin shoutly-guide-tour($config-or-theme, $roundnessMap) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $foreground: map.get($color-config, foreground);
    $primary: map.get($color-config, primary);

    .tour-step {
        .tour-block {
            border-radius: map-get($roundnessMap, "buttons");
            .tour-title {
                color: mat.m2-get-color-from-palette($foreground, text);
                padding-bottom: initial !important;
            }
            .tour-content {
                min-height: initial !important;
                color: mat.m2-get-color-from-palette($foreground, secondary-text);
            }
            .tour-buttons {
                button {
                    font-size: initial !important;
                    padding: 8px 16px !important;
                    line-height: initial !important;
                }
                .back-button {
                    color: mat.m2-get-color-from-palette($primary);
                }
                .next-button {
                    background-color: mat.m2-get-color-from-palette($primary);
                    border-radius: 8px;
                    &:hover {
                        background-color: mat.m2-get-color-from-palette($primary, 800);
                    }
                }
            }
        }
    }
}

@mixin shoutly-snack-bar($config-or-theme) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);
    $warn: map.get($color-config, warn);

    .top-list-message {
        text-align: center;
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        padding: 20px;
        margin-bottom: 20px;
        &.warn {
            background-color: lighten($colorOrange, 35%);
            color: $colorOrange;
        }
    }

    .mat-mdc-snack-bar-container.shoutly-snack-bar {
        .mdc-snackbar__surface {
            height: 40px;
            max-width: initial;
            box-shadow: initial;
            padding: 25px;
            text-align: center;
            font-weight: 800;
            background-color: mat.m2-get-color-from-palette($background, card);
            color: mat.m2-get-color-from-palette($foreground, text);
            bottom: 50px;
            position: relative;

            .mat-mdc-snack-bar-label {
                width: 100%;

                &.mdc-snackbar__label::before {
                    content: " ";
                    height: 16px;
                    width: 16px;
                    position: absolute;
                    margin-left: -30px;
                    display: block;
                    margin-left: -25px;
                    bottom: calc(50% - 8px);
                }

                .mat-mdc-snack-bar-actions {
                    border: 1px solid mat.m2-get-color-from-palette($foreground, text);
                    border-radius: 9px;
                }
            }
        }

        &.warn {
            color: $colorOrange;

            .mdc-snackbar__surface {
                background-color: lighten($colorOrange, 35%);

                .mat-mdc-snack-bar-label {
                    .mdc-snackbar__label::before {
                        background-image: url("/assets/UI/snackBar/status-info.svg");
                    }
                    color: $colorOrange;

                    .mat-mdc-snack-bar-actions {
                        border-color: $colorOrange;

                        .mat-mdc-snack-bar-action {
                            color: $colorOrange;
                        }
                    }
                }
            }
        }

        &.error {
            .mdc-snackbar__surface {
                background-color: lighten($colorRed, 35%);

                .mat-mdc-snack-bar-label {
                    .mdc-snackbar__label::before {
                        background-image: url("/assets/UI/snackBar/status-error.svg");
                    }
                    color: $colorRed;

                    .mat-mdc-snack-bar-actions {
                        border-color: $colorRed;

                        .mat-mdc-snack-bar-action {
                            color: $colorRed;
                        }
                    }
                }
            }
        }

        &.success {
            .mdc-snackbar__surface {
                background-color: lighten($colorGreen, 35%);

                .mat-mdc-snack-bar-label {
                    .mdc-snackbar__label::before {
                        background-image: url("/assets/UI/snackBar/status-success.svg");
                    }
                    color: $colorGreen;
                }

                .mat-mdc-snack-bar-actions {
                    border-color: $colorGreen;

                    .mat-mdc-snack-bar-action {
                        color: $colorGreen;
                    }
                }
            }
        }

        &.info {
            .mdc-snackbar__surface {
                background-color: lighten($colorBlue, 35%);

                .mat-mdc-snack-bar-label {
                    .mdc-snackbar__label::before {
                        background-image: url("/assets/UI/snackBar/status-info.svg");
                    }
                    color: $colorBlue;

                    .mat-mdc-snack-bar-actions {
                        border-color: $colorBlue;

                        .mat-mdc-snack-bar-action {
                            color: $colorBlue;
                        }
                    }
                }
            }
        }

        a[href] {
            color: mat.m2-get-color-from-palette($foreground, 500-contrast);
            font-weight: 800;
        }
    }
}

@mixin shoutly-tooltip($config-or-theme) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $primary: map.get($color-config, primary);
    $foreground: map.get($color-config, foreground);
    $warn: map.get($color-config, warn);

    .mat-mdc-tooltip {
        .mdc-tooltip__surface {
            padding: 17px 15px !important;
            opacity: 0.9 !important;
            font-size: 14px;
            background-color: mat.m2-get-color-from-palette($primary);
            &.warning {
                background-color: mat.m2-get-color-from-palette($warn);
            }
        }
    }
}

@mixin shoutly-loader($config-or-theme, $roundnessMap) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $primary: map.get($color-config, primary);
    $circle: radial-gradient(circle 8px, mat.m2-get-color-from-palette($primary) 99%, transparent 100%);

    .shoutly-spinner {
        border: 16px solid mat.m2-get-color-from-palette($primary, 100);
        border-top: 16px solid mat.m2-get-color-from-palette($primary);
        &::before {
            background:
                $circle left -9px top 19px,
                $circle right -9px top -1px;
        }
    }

    mat-progress-bar.mat-mdc-progress-bar[mode="indeterminate"] {
        transform: scaleY(3);
        border-radius: map-get($roundnessMap, "buttons");
    }
}

@mixin shoutly-boxes($config-or-theme, $roundnessMap) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $background: map.get($color-config, background);
    $foreground: map.get($color-config, foreground);
    $primary: map.get($color-config, primary);
    $is-dark-theme: map.get($color-config, is-dark);

    .summary-boxes-block {
        .box {
            border-radius: map-get($roundnessMap, "cards");
            background-color: mat.m2-get-color-from-palette($background, card);
            .content {
                .label {
                    color: mat.m2-get-color-from-palette($foreground, "secondary-text");
                }
            }
        }
    }
}

@mixin shoutly-inputs($config-or-theme, $roundnessMap) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $typography-config: mat.m2-get-typography-config($config-or-theme);
    $primary: map.get($color-config, primary);
    $foreground: map.get($color-config, foreground);
    $background: map.get($color-config, background);
    $warn: map.get($color-config, warn);

    .input-file-box {
        font-family: mat.m2-font-family($typography-config);
        width: 100%;
        cursor: pointer;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: #{mat.m2-get-color-from-palette($primary, A200)} + 3F;
        border: 1px dashed mat.m2-get-color-from-palette($foreground, text);
        color: mat.m2-get-color-from-palette($foreground, text);
        transition: border 0.2s;
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-bottom: 20px;
        .mat-icon {
            vertical-align: middle;
        }
        p {
            vertical-align: middle;
            display: inline-block;
            padding: 5px;
            text-decoration: none;
        }
        &:hover {
            border: 1px dashed mat.m2-get-color-from-palette($primary, 300);
        }

        &.normal {
            background-color: #{mat.m2-get-color-from-palette($primary, A200)} + 3F;
            border: 1px dashed mat.m2-get-color-from-palette($foreground, text);
            color: mat.m2-get-color-from-palette($foreground, text);
        }

        &.error {
            border: 2px solid mat.m2-get-color-from-palette($warn, 500);
            color: mat.m2-get-color-from-palette($warn, 900);
        }

        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
            background-color: #{mat.m2-get-color-from-palette($background, card)};
            border: 1px dashed mat.m2-get-color-from-palette($foreground, divider);
            color: mat.m2-get-color-from-palette($foreground, hint);
            pointer-events: none;
        }
    }

    mat-select {
        &.payout-currency-dropdown {
            margin-left: 6px;
            width: auto;
            .mat-mdc-select-value {
                color: mat.m2-get-color-from-palette($primary, 800);
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
            }
        }
    }

    mat-option {
        &.mat-mdc-option {
            &:hover {
                font-weight: 600;
                color: darken(mat.m2-get-color-from-palette($primary, 800), 10%);
            }
            .mdc-list-item__primary-text {
                color: mat.m2-get-color-from-palette($primary, 800);
            }
        }
    }

    mat-form-field {
        &.mat-mdc-form-field {
            .mdc-text-field {
                .mdc-notched-outline {
                    .mdc-notched-outline__leading,
                    .mdc-notched-outline__notch,
                    .mdc-notched-outline__trailing {
                        border-color: mat.m2-get-color-from-palette($background, "status-bar");
                    }
                }
                .mat-form-field.mat-form-field-appearance-fill:not(.mat-form-field-disabled) {
                    /* Target the input element within the floating label */
                    .mat-form-field-label .mat-input-element {
                        /* Set the text color using CSS properties */
                        color: mat.m2-get-color-from-palette($foreground, "secondary-text");
                    }
                }
                // &:not(.mdc-text-field--disabled) {
                //     .mdc-floating-label .mdc-text-field__input {
                //         @include floating-label-mixins.ink-color(
                //             mat.m2-get-color-from-palette($foreground, "secondary-text")
                //         );
                //     }
                // }
                &.mdc-text-field--invalid .mdc-notched-outline {
                    .mdc-notched-outline__leading,
                    .mdc-notched-outline__notch,
                    .mdc-notched-outline__trailing {
                        border-color: mat.m2-get-color-from-palette($warn);
                    }
                }
                &.mdc-text-field--focused:not(.mdc-text-field--invalid) .mdc-notched-outline {
                    .mdc-notched-outline__leading,
                    .mdc-notched-outline__notch,
                    .mdc-notched-outline__trailing {
                        border-color: mat.m2-get-color-from-palette($primary, 800);
                    }
                }
            }

            .mat-mdc-form-field-icon-prefix {
                color: mat.m2-get-color-from-palette($primary, 800);
            }
            .mat-mdc-form-field-icon-suffix {
                .mat-mdc-tooltip-trigger {
                    color: mat.m2-get-color-from-palette($foreground, "secondary-text");
                }
                // .mdc-icon-button {
                //     color: mat.get-color-from-palette($primary, 800);
                //     font-weight: 500;
                // }
            }

            .mat-mdc-form-field-subscript-wrapper {
                display: flex;
                white-space: nowrap;
                .mat-mdc-form-field-hint-wrapper,
                .mat-mdc-form-field-error-wrapper {
                    overflow: hidden;
                    position: relative;
                    width: 100%;
                    .mat-mdc-form-field-hint,
                    .mat-mdc-form-field-error {
                        margin-bottom: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        &:empty {
                            display: none;
                        }
                    }
                }
                .mat-mdc-form-field-hint-wrapper {
                    .mat-mdc-form-field-hint {
                        color: mat.m2-get-color-from-palette($foreground, "secondary-text");
                    }
                }
            }
        }
        &.mat-form-field-disabled {
            .mat-icon {
                color: mat.m2-get-color-from-palette($foreground, secondary-text);
            }
        }
    }

    .form-group-vertical {
        padding: 10px;
        &.form-highlight {
            border-radius: 8px;
            background-color: #{darken(mat.m2-get-color-from-palette($background, app-bar), 1%) + "78"};
        }
    }

    mat-radio-group {
        &.option-group {
            .option {
                box-shadow: 0 0 0 2px rgba(mat.m2-get-color-from-palette($foreground, "secondary-text"), 0.1);
                &:not(.mat-mdc-radio-disabled) {
                    box-shadow: 0 0 0 2px rgba(mat.m2-get-color-from-palette($primary, 800), 0.8);
                    .mdc-label {
                        color: mat.m2-get-color-from-palette($primary, 800);
                    }
                    &:hover {
                        background: darken(mat.m2-get-color-from-palette($background, "card"), 5%);
                    }
                }
            }
        }
        &.error{
            padding: 5px;
            border: 1px solid mat.m2-get-color-from-palette($warn);
        }
    }

    ngx-mat-select-search {
        .mat-select-search-inner {
            input {
                &.mat-select-search-input {
                    background-color: mat.m2-get-color-from-palette($background, "card");
                }
            }
        }
    }

    .search-bar {
        background-color: mat.m2-get-color-from-palette($background, "card");
    }
}

@mixin shoutly-chips($config-or-theme) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $primary: map.get($color-config, primary);
    $is-dark-theme: map.get($color-config, is-dark);

    .role {
        position: relative;
        & > span {
            background-color: mat.m2-get-color-from-palette($primary, 50);
            @if $is-dark-theme {
                background-color: mat.m2-get-color-from-palette($primary, 900);
            }
        }
    }

    .tags-bar {
        .mat-mdc-chip-set {
            .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
                background-color: mat.m2-get-color-from-palette($primary, 50);
                .mdc-evolution-chip__text-label {
                    color: mat.m2-get-color-from-palette($primary, 800);
                }
                .mdc-evolution-chip__icon--primary {
                    color: mat.m2-get-color-from-palette($primary, 800);
                }
                .mdc-evolution-chip__cell--trailing {
                    .mdc-evolution-chip__icon {
                        color: mat.m2-get-color-from-palette($primary, 800);
                    }
                }
            }
        }
    }
}

@mixin shoutly-dashboard-widgets($config-or-theme, $roundnessMap) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $primary: map.get($color-config, primary);
    $background: map.get($color-config, background);
    $foreground: map.get($color-config, foreground);

    .dashboard-data-card-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 16px;
        width: 100%;
        box-sizing: border-box;
        .dashboard-data-card {
            display: flex;
            gap: 12px;
            padding: 20px;
            background-color: mat.m2-get-color-from-palette($background, "background");
            line-height: 21px;
            align-items: center;
            border-radius: map-get($roundnessMap, "cards");
            overflow: hidden;
            min-height: 76px;
            & > div {
                display: block;
                width: 100%;
            } 
            .dashboard-data-card-image {
                width: 56px;
                height: 56px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                aspect-ratio: 1;
                background-color: mat.m2-get-color-from-palette($background, card);
                border: 1px solid rgba(mat.m2-get-color-from-palette($foreground, secondary-text), 0.09);
                img{
                    width: 56px;
                    height: 56px;
                    border-radius: 100%;
                }
            }

            /* Specific card background colors based on class */
            &.active-collaborations {
                .dashboard-data-card-image {
                    background-color: #f5c324;
                    color: white;
                    border: initial;
                }
            }
    
            &.total-collaborations {
                .dashboard-data-card-image {
                    background-color: #ff8a65;                
                    color: white;
                    border: initial;
                }
            }
    
            &.total-cost {
                .dashboard-data-card-image {
                    background-color: #806cff;
                    color: white;
                    border: initial;
                }
            }

            .dashboard-data-card-content{
                display: flex;
                justify-content: space-between;
                width: 100%;
                overflow: hidden;
                .dashboard-data-card-pane{
                    display: flex;
                    justify-content: center;
                    white-space: nowrap;
                    position: relative;
                    flex-direction: column;
                    align-items: flex-start;
                    overflow: hidden;
                    .secondary-text {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    * {
                        max-width: 100%;
                    }
                    &.right{
                        align-items: flex-end;
                    }
                    .amount{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
            .featured-value {
                font-size: 18px;
                line-height: 21px;
                font-weight: 700;
            }
            .variation-value {
                font-size: smaller;
            }
        }
        .dashboard-data-card-expandable{
            background-color: mat.m2-get-color-from-palette($background, "background");
            padding: 0 12px;
            gap: 12px;
            .dashboard-data-card {
                background-color: initial;
                border-radius: initial;
                padding: initial;
            }
        }
        .variation-up {
            color: green;
        }

        .variation-down {
            color: red;
        }
        .skeleton{
            width: 100%;
            margin-bottom: 8px;
        }
    }
    
}

@mixin shoutly-infoboxes($config-or-theme, $roundnessMap) {
    $color-config: mat.m2-get-color-config($config-or-theme);
    $background: map.get($color-config, background);

    .infobox-status{
        background-color: mat.m2-get-color-from-palette($background, "card");
        &.negative{
            border-color: $colorRed;
            .icon{
                color: $colorRed;
            }
        }
        &.warning{
            border-color: $colorOrange;
            .icon{
                color: $colorOrange;
            }
        }
    }
}