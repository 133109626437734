@import 'https://fonts.googleapis.com/css?family=Material+Icons+Outlined';

@font-face {
  font-family: 'Gilroy';
  src: url('/assets/fonts/Gilroy/Gilroy-Light.woff2') format('woff2'),
       url('/assets/fonts/Gilroy/Gilroy-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

 @font-face {
  font-family: 'Gilroy';
  src: url('/assets/fonts/Gilroy/Gilroy-Regular.woff2') format('woff2'),
       url('/assets/fonts/Gilroy/Gilroy-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/assets/fonts/Gilroy/Gilroy-Medium.woff2') format('woff2'),
       url('/assets/fonts/Gilroy/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/assets/fonts/Gilroy/Gilroy-SemiBold.woff2') format('woff2'),
       url('/assets/fonts/Gilroy/Gilroy-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/assets/fonts/Gilroy/Gilroy-Bold.woff2') format('woff2'),
       url('/assets/fonts/Gilroy/Gilroy-Bold.woff') format('woff');      
  font-weight: 700;
  font-style: normal;
}