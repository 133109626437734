$colorRed: #f24a35; // 700

$colorSofterOrange: #FCF6EC; // 50
$colorSoftOrange: #EBDED8; // 100
$colorOrangeLight: #E0A041;
$colorOrange: #E08441; // 500

$colorBlue: #1F5BB6; // A400
$colorBluev2: #2e5cb0; // A200
$colorBluev1: #04417C; //#184178

$colorGrey:  #757F8B;// #717E8A;
$colorGreyv1: #E0E0E0;

$colorYellow: #FFF7D6;
$colorYellowv1: #CF9F02;

$colorGreen: #5cc35a;
$colorGreenv2: #2DB22A;

$break-mobile: 480px;
$break-tablet: 720px;
$break-desktop: 1080px;
$break-max: 1280px;

$height-nav-component: 72px;