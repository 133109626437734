@use 'sass:map';
@use '@angular/material' as mat;
@use '_shoutly-palettes' as shoutly-palette;
@use 'get-default-theme' as _theme;

// Define font configuration
$fontConfig: mat.m2-define-typography-config(
  $font-family: "Gilroy",
  $headline-5: mat.m2-define-typography-level($font-weight: 700, $font-size: 24px, $letter-spacing: 0.02em, $line-height: 32px),
  $headline-6: mat.m2-define-typography-level($font-weight: 700, $font-size: 20px, $letter-spacing: 0.02em, $line-height: 28px),
  $body-2: mat.m2-define-typography-level($font-weight: 400, $font-size: 14px, $line-height: 20px),
  $subtitle-1: mat.m2-define-typography-level($font-weight: 600, $font-size: 1.17em, $line-height: 28px)
);

// Include core styles and typography only once
@include mat.core();
@include mat.all-component-typographies($fontConfig);

// Define themes
$defaultTheme: _theme.get-default-theme($fontConfig);
$darkTheme: _theme.get-dark-theme($fontConfig);

$defaultThemeRoundnessMap: (
  'buttons': 4px,
  'cards': 4px
);

$darkThemeRoundnessMap: $defaultThemeRoundnessMap;

// Mixin to apply custom theme styles
@mixin apply-custom-theme($theme, $roundnessMap, $useAlternateColor: false) {
  @include shoutly-logo($theme, $useAlternateColor);
  @include shoutly-menues($theme);
  @include shoutly-tabs($theme);
  @include shoutly-text-defaults($theme);
  @include shoutly-svg($theme);
  @include shoutly-buttons-theme($theme);
  @include shoutly-card-item-collaboration($theme);
  @include shoutly-user-item($theme);
  @include shoutly-layout-components($theme, $roundnessMap);
  @include shoutly-notifications($theme);
  @include shoutly-mat-cards($theme, $roundnessMap);
  @include shoutly-mat-tables($theme, $roundnessMap);
  @include shoutly-sidenav($theme, $roundnessMap, $useAlternateColor);
  @include shoutly-statuses($theme);
  @include shoutly-dialogs($theme, $roundnessMap);
  @include shoutly-accordions($theme, $roundnessMap);
  @include shoutly-guide-tour($theme, $roundnessMap);
  @include shoutly-snack-bar($theme);
  @include shoutly-tooltip($theme);
  @include shoutly-loader($theme, $roundnessMap);
  @include shoutly-boxes($theme, $roundnessMap);
  @include shoutly-inputs($theme, $roundnessMap);
  @include shoutly-chips($theme);
  @include shoutly-dashboard-widgets($theme, $roundnessMap);
  @include shoutly-infoboxes($theme, $roundnessMap);
}

// Apply default theme styles
@include mat.all-component-themes($defaultTheme);
@include apply-custom-theme($defaultTheme, $defaultThemeRoundnessMap, true);

// Apply dark theme styles conditionally
body.dark-theme {
  $dark-background-palette: map.get(mat.m2-get-color-config($darkTheme), 'background');
  $dark-foreground-palette: map.get(mat.m2-get-color-config($darkTheme), 'foreground');

  background-color: mat.m2-get-color-from-palette($dark-background-palette, 'background');
  color: mat.m2-get-color-from-palette($dark-foreground-palette, 'text') !important;

  @include mat.all-component-colors($darkTheme);
  @include apply-custom-theme($darkTheme, $darkThemeRoundnessMap);
}
